$background-primary: #0a1420;
$primary: #4F46E5;
$secondary: #6B7280;
$success-primary: #1C7C54;
$success-secondary: #D4EDDA;
$info-primary: #285ECA;
$info-secondary: #DCEEFF;
$warning-primary: #B78C0B;
$warning-secondary: #FFF4CC;
$error-primary: #C53030;
$error-secondary: #FDDCDC;