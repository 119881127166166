@keyframes pulse {
    0% {
        box-shadow: 0 0 10px rgba(79, 70, 229, 0.5);
    }
    50% {
        box-shadow: 0 0 20px rgba(79, 70, 229, 0.7);
    }
    100% {
        box-shadow: 0 0 10px rgba(79, 70, 229, 0.5);
    }
}

.pulse {
    animation: pulse 1.5s infinite;
}