@import '../../styles.scss';

.custom-success-toast {
    background-color: $success-primary;
    color: $success-secondary;
}

.custom-info-toast {
    background-color: $info-primary;
    color: $info-secondary;
}

.custom-warning-toast {
    background-color: $warning-primary;
    color: $warning-secondary;
}

.custom-error-toast {
    background-color: $error-primary;
    color: $error-secondary;
}