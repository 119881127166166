@import './styles.scss';

body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: $background-primary;
}

h1 {
    font-size: 2rem;
}

@media (min-width: 640px) {
    h1 {
        font-size: 2.5rem;
    }
}